import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  TemplateRef,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { Helper } from '../shared/helper';
import { CartService } from '../shared/cart.service';
import { LoginService } from '../shared/login.service';
import { OfferService } from '../shared/offer.service';
declare var google: any;
declare var jQuery: any;
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LoginRegisterModalComponent } from '../login-register-modal/login-register-modal.component';
import { DeliverySettingService } from '../shared/delivery-setting.service';
import { DeliveryErrorModalComponent } from '../delivery-error-modal/delivery-error-modal.component';
import { Location } from '@angular/common';
import { Params } from '@angular/router';

export interface CartProductItems {
  item_id: Object;
  unique_id: Number;
  item_name: string;
  quantity: Number;
  image_url: any;
  details: string;
  specifications: any[];
  formule_list: any[];
  item_price: Number;
  offer_price: Number;
  is_buy_one_get_one: boolean;
  total_specification_price: Number;
  total_item_and_specification_price: Number;
  note_for_item: string;
}

export interface cartProducts {
  items: any[];
  product_id: Object;
  product_name: string;
  unique_id: number;
  total_item_price: number;
}

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./shop.component.scss'],
  providers: [
    Helper,
    CartService,
    DeliverySettingService,
    OfferService,
    LoginService,
  ],
})
export class ShopComponent implements OnInit, OnDestroy {
  constructor(
    private location: Location,
    public helper: Helper,
    public login_service: LoginService,
    public offer_service: OfferService,
    private modalService: NgbModal,
    public delivery_setting_service: DeliverySettingService,
    public cart_service: CartService
  ) {}

  favourite_addresses: any[] = [];
  session_subscriber: any;
  user_id: string = '';
  server_token: string = '';
  current_store: any = {};
  is_language_dropdown: boolean = false;

  is_address_valid: boolean = true;
  is_header_sticky: boolean = false;

  @ViewChild('AccountPopup') AccountPopup!: LoginRegisterModalComponent;
  @ViewChild('ScopeOfDelivery') ScopeOfDelivery!: DeliveryErrorModalComponent;
  @ViewChild('ProductItem') ProductItem!: any;
  @ViewChild('ClearCart') ClearCart!: any;

  product_list: any[] = [];
  filtered_product_list: any[] = [];
  selected_product_id: string = '';
  is_open_product_dropdown: boolean = false;
  delivery_currency: string = '';
  header_height: number = 0;
  margin_top: number = 0;

  is_show_cart: boolean = false;
  is_show_header_error: boolean = false;
  is_register_button: boolean = true;
  is_second_text: boolean = true;
  login_header_text: string = this.helper.text.pay_text3;

  // cart_offer_message: string = '';
  // cart_offer_value: number = 0;
  // cart_offer_error: string = '';
  // cart_offer_error2: string = '';
  // cart_offer_progress_bar: number = -1;
  // delivery_offers: any[] = [];
  // is_show_cart_offer:boolean=false;
  is_show_header_popup: boolean = false;

  current_item: any = {};
  current_formule: any = {};
  current_main_item: any = {};
  required_count: number = 0;
  total: number = 0;
  required_temp_count: number = 0;
  qty: number = 1;
  note_for_item: string = '';
  product_name: string = '';
  product_unique_id: any = null;
  private cartProductItems: CartProductItems = {
    item_id: '',
    unique_id: 0,
    item_name: '',
    quantity: 0,
    image_url: [],
    formule_list: [],
    details: '',
    specifications: [],
    item_price: 0,
    offer_price: 0,
    is_buy_one_get_one: false,
    total_specification_price: 0,
    total_item_and_specification_price: 0,
    note_for_item: '',
  };
  private cartProducts: cartProducts = {
    items: [],
    product_id: '',
    product_name: '',
    unique_id: 0,
    total_item_price: 0,
  };
  modalRef: any;
  selected_item_index: number = 0;
  selected_product_index: number = 0;
  edit_cart_item: boolean = false;

  order_history: any[] = [];

  // is_schedule_order: Boolean = false;
  // schedule_date: any = '';
  // schedule_time: string = '';
  // is_show_date:boolean=false;
  // is_show_time:boolean=false;
  // schedule_time_error:boolean=false;
  // date_array: any[] = [];
  // time_array: any[] = [];
  // clicked_date: any = null;
  // server_date: any = null;

  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  async ngOnInit() {
    setTimeout(() => {
      var elem: any = document.getElementById('shop-header-main');
      this.header_height = elem.offsetHeight;
    });

    this.session_subscriber =
      this.helper.common_data.session_observable.subscribe((data) => {
        this.check_detail();
      });

    var detail = this.helper.common_data.observable.subscribe(async (data) => {
      if (data) {
        setTimeout(() => {
          if (detail) {
            detail.unsubscribe();
          }
        });
        if (data.current_store) {
          this.current_store = data.current_store;

          // this.delivery_setting_service.is_close_asap = data.is_close_asap;
          this.delivery_setting_service.schedule_time = data.schedule_time;
          this.delivery_setting_service.schedule_date = data.schedule_date;
          this.delivery_setting_service.clicked_date = data.clicked_date;
        }
        this.current_store = data;
        this.get_product_item_list(data);
        // if(!this.helper.common_data.cart_data.ipickup_delivery && (!this.helper.common_data.cart_data.destination_address.location || !this.helper.common_data.cart_data.destination_address.location[0] || !this.helper.common_data.cart_data.destination_address.location[1])){
        //   this.helper.router.navigate(['/home']);
        // } else {

        // }
      }
    });
  }

  async get_product_item_list(data: any) {
    this.helper.loader_service.main_loading = true;
    this.is_show_cart = false;
    var json: any = {
      store_id: this.helper.website_data.detail.store_id,
      cart_unique_token: localStorage.getItem('cart_unique_token'),
      is_catering: this.helper.common_data.cart_data.is_catering,
    };

    if (localStorage['is_schedule_order'] == 'true') {
      let date = localStorage['schedule_date'].split('-');

      let time = localStorage['schedule_time'].split(' ')[0].split(':');

      let hour = Number(time[0]);
      let ampm = localStorage['schedule_time'].split(' ')[1];
      if (ampm == 'PM' && hour < 12) {
        time[0] = String(Number(time[0]) + 12);
      } else if (ampm === 'AM' && hour === 12) {
        time[0] = String(0); // Convert midnight (12 AM) to 0 hours
      }

      let selected_date: any = new Date(Date.now());
      selected_date.setDate(date[2]);
      selected_date.setMonth(date[1] - 1);
      selected_date.setFullYear(date[0]);
      selected_date.setHours(time[0], time[1], 0, 0);
      selected_date = new Date(selected_date);
      selected_date = new Date(selected_date).toLocaleString('en-US', {
        timeZone: this.helper.common_data.cart_data.timezone,
      });

      console.log(selected_date);
      json['selected_date'] = selected_date;
    }

    var res_data = await this.helper.http_post_method_requester(
      this.helper.POST_METHOD.USER_GET_STORE_PRODUCT_ITEM_LIST,
      json,
      true,
      true,
      true,
      true
    );
    this.helper.loader_service.main_loading = false;
    if (res_data.success) {
      this.current_store = res_data.store;
      if (!this.current_store.is_zone_delivery_price) {
        this.helper.common_data._user_cart.cart_data.min_order_price =
          this.current_store.min_order_price;
      }

      if (this.current_store.order_type == 1) {
        this.helper.common_data.cart_data.ipickup_delivery = true;
      }

      if (this.current_store.is_business && this.current_store.is_approved) {
        this.helper.common_data.cart_data.timezone = res_data.timezone;
        this.helper.common_data.cart_data.server_date = res_data.server_time;
        this.delivery_currency = res_data.currency;
        this.delivery_setting_service.is_emergency = res_data.is_emergency;
        this.delivery_setting_service.emergency_close_till =
          res_data.emergency_close_till;

        this.helper.common_data.cart_data.pickup_address.address =
          this.current_store.address_components;
        this.helper.common_data.cart_data.pickup_address.location =
          this.current_store.location;
        this.helper.common_data.cart_data.city_id = this.current_store.city_id;
        this.product_list = res_data.products;
        this.filtered_product_list = res_data.products;
        this.filtered_product_list.sort(this.sortOnOff);
        this.filtered_product_list.sort(this.sortProduct);

        let specification_price: any;
        var date: Date = res_data.server_time;
        date = new Date(
          new Date(date).toLocaleString('en-US', {
            timeZone: res_data.timezone,
          })
        );
        let product_array = res_data.products.filter((product_data: any) => {
          if (this.check_product_available(product_data._id, date)) {
            let item_array = product_data.items.filter((item_data: any) => {
              specification_price = 0;
              item_data.specifications.filter((specification_group: any) => {
                specification_group.list.filter((specification: any) => {
                  specification.price = Number(specification.price);
                  if (
                    specification.is_default_selected &&
                    !specification.is_close &&
                    !specification_group.is_close
                  ) {
                    if (!specification_group.formule_id) {
                      specification_price =
                        specification_price + Number(specification.price);
                    }
                  }
                });
                if (
                  specification_group.min_selection == undefined ||
                  specification_group.max_selection == undefined
                ) {
                  if (specification_group.type == 1) {
                    specification_group.min_selection = 1;
                    specification_group.max_selection = 1;
                    // specification_group.is_required = true;
                  } else {
                    specification_group.max_selection =
                      specification_group.list.length;
                    if (specification_group.is_required) {
                      specification_group.min_selection = 1;
                    } else {
                      specification_group.min_selection = 0;
                    }
                  }
                }
                if (specification_group.max_selection == 0) {
                  specification_group.max_selection = 1;
                }
              });

              let total_item_price = item_data.price + specification_price;
              item_data.total_item_price = total_item_price;
              return item_data;
            });
            product_data.items = item_array;
            product_data.items.sort(this.sortItem);
            return product_data;
          }
        });

        if (this.filtered_product_list.length > 0) {
          this.select_product(this.filtered_product_list[0]._id._id);
        }
        this.delivery_setting_service.check_and_set_schedule(
          this.current_store
        );
        if (this.helper.common_data.cart_data.is_schedule_order) {
          this.delivery_setting_service.check_open(
            this.current_store,
            this.helper.common_data.cart_data.schedule_date,
            false,
            true
          );
        } else {
          let date: any = res_data.server_time;
          date = new Date(date).toLocaleString('en-US', {
            timeZone: res_data.timezone,
          });
          date = new Date(date);
          this.delivery_setting_service.check_open(
            this.current_store,
            date,
            true,
            true
          );
        }
        this.set_product_item_offer_tag();
        this.offer_service.check_cart_offer(
          this.current_store,
          this.delivery_currency
        );
        this.offer_service.check_delivery_offer(
          this.current_store,
          this.delivery_currency
        );

        this.helper.route.params.subscribe((x: Params) => {
          if (x['table_no'] || this.helper.router.url == '/shop/table') {
            this.helper.common_data.cart_data.ipickup_delivery = true;
            this.helper.common_data.cart_data.is_order_pay = true;
            this.helper.common_data.cart_data.table_no = x['table_no']
              ? x['table_no']
              : '';
            if (x['item_name']) {
              this.check_item_and_open_modal(x['item_name']);
            } else {
              this.location.replaceState(
                'shop/table/' + this.helper.common_data.cart_data.table_no
              );
            }
          } else {
            if (x['item_name']) {
              this.check_item_and_open_modal(x['item_name']);
            } else {
              this.location.replaceState('/shop');
            }
          }
        });

        if (!data._id) {
          var current_location = localStorage.getItem('current_location');
          if (current_location) {
            this.delivery_setting_service.check_valid_address(
              JSON.parse(current_location),
              this.current_store,
              false
            );
          } else {
          }
        }
      } else {
        this.helper.router.navigate(['/']);
      }
    } else {
      this.filtered_product_list = [];
      this.product_list = [];
    }
  }

  check_item_and_open_modal(item_name: string) {
    item_name = item_name.replace(/_/g, ' ');
    var bool = false;
    const filteredResult = this.filtered_product_list.filter((product) => {
      let index = product.items.findIndex((x: any) => x.name == item_name);
      if (index != -1) {
        this.open_item_modal(
          this.ProductItem,
          product.items[index],
          product._id.name,
          product._id.unique_id
        );
        bool = true;
      }
    });
    if (!bool) {
      if (this.helper.common_data.cart_data.is_order_pay) {
        this.location.replaceState(
          'shop/table/' + this.helper.common_data.cart_data.table_no
        );
      } else {
        this.location.replaceState('/shop');
      }
    }
  }

  ngOnDestroy() {
    if (this.session_subscriber) {
      this.session_subscriber.unsubscribe();
    }
  }

  check_detail() {
    if (localStorage['user']) {
      let user = JSON.parse(localStorage['user']);
      if (user && user._id) {
        this.user_id = user._id;
        this.server_token = user.server_token;
        this.delivery_setting_service.get_fav_address({
          user_id: this.user_id,
          server_token: this.server_token,
        });
      }
    } else {
      this.user_id = '';
      this.server_token = '';
    }
  }

  sortItem(a: any, b: any) {
    if (a.sequence_number < b.sequence_number) return -1;
    if (a.sequence_number > b.sequence_number) return 1;
    return 0;
  }

  sortProduct(a: any, b: any) {
    if (
      a._id.sequence_number < b._id.sequence_number &&
      a._id.is_visible_in_store
    )
      return -1;
    if (a._id.sequence_number > b._id.sequence_number) return 1;
    return 0;
  }

  sortFormule(a: any, b: any) {
    if (a.formule_id) return -1;
    return 0;
  }

  sortOnOff(a: any, b: any) {
    if (a._id.is_visible_in_store > b._id.is_visible_in_store) return -1;
    if (a._id.is_visible_in_store < b._id.is_visible_in_store) return 1;
    return 0;
  }

  check_product_available(product_data: any, server_date: any) {
    var date = new Date(server_date);
    if (!product_data.is_product_time) {
      product_data.is_available = true;
    } else {
      let current_time = date.getTime();
      product_data.is_available = false;
      let weekday = date.getDay();
      let week_index = product_data.product_time.findIndex(
        (x: any) => x.day == weekday
      );

      if (product_data.product_time[week_index].is_available) {
        let day_time = product_data.product_time[week_index].day_time;

        day_time.forEach((product_time: any, index: number) => {
          let open_time = product_time.open_time;
          open_time = open_time.split(':');
          let x = date.setHours(open_time[0], open_time[1], 0, 0);
          let x1 = new Date(x);
          let x2 = x1.getTime();

          let close_time = product_time.close_time;
          close_time = close_time.split(':');
          let y = date.setHours(close_time[0], close_time[1], 0, 0);
          let y1 = new Date(y);
          let y2 = y1.getTime();

          if (current_time >= x2 && current_time <= y2) {
            product_data.is_available = true;
            product_data.available_text =
              'Disponible jusqu’à ' + product_time.close_time;
          }
          if (current_time < x2) {
            product_data.available_text =
              'Disponible dès ' + product_time.open_time;
          }
        });
        if (!product_data.is_available && !product_data.available_text) {
          if (day_time.length > 0) {
            product_data.available_text =
              'Disponible entre ' +
              day_time[0].open_time +
              ' et ' +
              day_time[0].close_time;
          } else {
            // product_data.available_text = 'Non disponible actuellement'
          }
        }
      } else {
        // product_data.available_text = 'Non disponible actuellement'
      }
    }
    return true;
  }

  go_to_home() {
    if (this.helper.common_data.cart_data.is_order_pay) {
      this.helper.router.navigate(['/home/table']);
    } else {
      this.helper.router.navigate(['/home']);
    }
  }

  select_product(product_id: string) {
    this.selected_product_id = product_id;
    this.is_open_product_dropdown = false;
    var elem: any = document.getElementById('product' + product_id);
    if (elem) {
      const y: any = elem.offsetTop - this.header_height - 20;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  set_product_item_offer_tag() {
    if (this.current_store.offers) {
      this.current_store.offers.forEach((offer: any) => {
        if (offer.offer_type == 1 || offer.offer_type == 2) {
          offer.product_item_ids.forEach((product_item_id: string) => {
            this.check_product_item_offer(product_item_id, offer);
          });
        }
      });
    }
  }

  check_product_item_offer(product_item_id: string, offer: any) {
    this.filtered_product_list.forEach(
      (product_detail: any, product_index: number) => {
        if (product_item_id.toString() == product_detail._id._id.toString()) {
          if (!product_detail.offer_tags) {
            product_detail.offer_tags = [];
          }
          product_detail.offer_tags.push(offer);
          product_detail.items.forEach((item_detail: any, item_index: any) => {
            if (!item_detail.offer_tags) {
              item_detail.offer_tags = [];
            }
            item_detail.offer_tags.push(offer);
            if (offer.offer_type == 2 && !item_detail.offer_price) {
              item_detail.offer_price = 0;
              var discount_value = offer.discount_value;
              if (offer.discount_type == 0) {
                if (discount_value > 100) {
                  discount_value = 100;
                }
                item_detail.offer_price =
                  item_detail.price -
                  (item_detail.price * discount_value) / 100;
              } else {
                if (discount_value > item_detail.price) {
                  discount_value = item_detail.price;
                }
                item_detail.offer_price = item_detail.price - discount_value;
              }
            }
          });
        } else {
          product_detail.items.forEach((item_detail: any, item_index: any) => {
            if (product_item_id.toString() == item_detail._id.toString()) {
              if (!item_detail.offer_tags) {
                item_detail.offer_tags = [];
              }
              item_detail.offer_tags.push(offer);
              if (offer.offer_type == 2) {
                item_detail.offer_price = 0;
                var discount_value = offer.discount_value;
                if (offer.discount_type == 0) {
                  if (discount_value > 100) {
                    discount_value = 100;
                  }
                  item_detail.offer_price =
                    item_detail.price -
                    (item_detail.price * discount_value) / 100;
                } else {
                  if (discount_value > item_detail.price) {
                    discount_value = item_detail.price;
                  }
                  item_detail.offer_price = item_detail.price - discount_value;
                }
              }
            }
          });
        }
      }
    );
  }

  open_item_modal(
    template: TemplateRef<any>,
    item: any,
    product_name: any,
    product_unique_id: any
  ) {
    var item_name = item.name.replace(/[^a-zA-Z0-9 ]/g, '');
    item_name = item_name.replace(/ /g, '_');
    if (!this.helper.common_data.cart_data.is_order_pay) {
      this.location.replaceState('shop/' + item_name);
    } else {
      this.location.replaceState(
        'shop/table/' +
          this.helper.common_data.cart_data.table_no +
          '/' +
          item_name
      );
    }
    this.qty = 1;
    this.required_count = 0;
    this.current_item = JSON.parse(JSON.stringify(item));
    this.current_formule = {};
    this.current_main_item = item;
    this.product_unique_id = product_unique_id;
    this.product_name = product_name;
    this.note_for_item = '';
    this.calculate_is_required();
    this.calculateTotalAmount();
    this.openModal1(template);
    // this.current_item.specifications.sort(this.sortFormule);
    this.edit_cart_item = false;
    if (this.offer_service.check_buy_one_get_one(this.current_item)) {
      this.current_item.is_buy_one_get_one = true;
    } else {
      this.current_item.is_buy_one_get_one = false;
    }
  }

  openModal1(template: TemplateRef<any>) {
    this.modalRef = this.modalService.open(template, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass: 'product-item-popup popup-mid',
    });
    this.modalRef.result.then(
      (result: any) => {
        if (!this.helper.common_data.cart_data.is_order_pay) {
          this.location.replaceState('/shop');
        } else {
          this.location.replaceState(
            'shop/table/' + this.helper.common_data.cart_data.table_no
          );
        }
      },
      (reason: any) => {
        if (!this.helper.common_data.cart_data.is_order_pay) {
          this.location.replaceState('/shop');
        } else {
          this.location.replaceState(
            'shop/table/' + this.helper.common_data.cart_data.table_no
          );
        }
      }
    );
  }
  openModal2(template: TemplateRef<any>) {
    this.modalService.open(template, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass: 'product-image-preview',
    });
  }

  select_formule(formule: any) {
    this.current_formule = JSON.parse(JSON.stringify(formule));
    console.log(this.current_formule);
    this.calculate_is_required();
    this.calculateTotalAmount();
  }

  calculate_is_required() {
    this.required_count = 0;
    this.current_item.specifications.forEach((specification_group: any) => {
      if (
        !specification_group.formule_id ||
        specification_group.formule_id == this.current_formule.formule_id
      ) {
        if (specification_group.is_required && !specification_group.is_close) {
          this.required_count++;
        }
        let list = specification_group.list.filter((x: any) => {
          if (!x.is_close) {
            return x;
          }
        });
        if (list.length < specification_group.min_selection) {
          specification_group.min_selection = list.length;
        }
        if (list.length < specification_group.max_selection) {
          specification_group.max_selection = list.length;
        }
      }
    });
  }

  calculateTotalAmount() {
    this.total = this.current_item.price;
    if (this.current_formule.formule_id && this.current_formule.price) {
      this.total = Number(this.current_formule.price);
    }
    this.required_temp_count = 0;
    this.current_item.specifications.forEach(
      (specification_group: any, specification_group_index: number) => {
        if (
          !specification_group.formule_id ||
          specification_group.formule_id == this.current_formule.formule_id
        ) {
          let isAllowed = false;
          var default_selected_count = 0;
          specification_group.is_disabled = true;
          specification_group.selected_specification = 0;
          specification_group.list.forEach(
            (specification: any, specification_index: number) => {
              if (
                specification.is_default_selected &&
                !specification.is_close &&
                !specification_group.is_close
              ) {
                this.total = this.total + specification.price;
                default_selected_count++;
                specification_group.selected_specification++;
              }
              specification_group.default_selected_count =
                default_selected_count;
            }
          );

          if (
            specification_group.type == 1 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= 1) {
              this.required_temp_count++;
              specification_group.is_disabled = false;
            }
          } else if (
            specification_group.type == 2 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= specification_group.min_selection) {
              this.required_temp_count++;
              specification_group.is_disabled = false;
            }
          }
        }
      }
    );

    this.total = this.total * this.qty;
    this.offer_service.check_item_offer(this.current_item, this.total);
  }

  changeradio(
    event: any,
    specification_group_index: number,
    specification_index: number
  ) {
    if (
      !this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_close
    ) {
      var index = this.current_item.specifications[
        specification_group_index
      ].list.findIndex((x: any) => x.is_default_selected == true);
      if (index !== -1) {
        this.current_item.specifications[specification_group_index].list[
          index
        ].is_default_selected = false;
      }
      this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_default_selected = true;
      this.calculateTotalAmount();
    }
  }

  changecheckbox(
    event: any,
    specification_group_index: number,
    specification_index: number
  ) {
    if (
      !this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_close
    ) {
      if (event.target.checked) {
        if (
          this.current_item.specifications[specification_group_index]
            .default_selected_count >=
          this.current_item.specifications[specification_group_index]
            .max_selection
        ) {
          var index = this.current_item.specifications[
            specification_group_index
          ].list.findIndex((x: any) => x.is_default_selected == true);
          this.current_item.specifications[specification_group_index].list[
            index
          ].is_default_selected = false;
        }
      }
      this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_default_selected = event.target.checked;
      this.calculateTotalAmount();
    }
  }

  handleClickOnAddToCart() {
    if (
      this.helper.common_data._user_cart.cart_data.cart.length === 0 ||
      this.helper.common_data._user_cart.cart_data.is_catering ==
        this.helper.common_data.cart_data.is_catering
    ) {
      this.addToCart();
    } else {
      console.log('different');
      if (this.modalRef) {
        this.modalRef.close();
      }
      this.modalRef = this.modalService.open(this.ClearCart, {
        centered: true,
        scrollable: true,
        size: 'lg',
        modalDialogClass: 'product-item-popup popup-mid',
      });
      this.modalRef.result.then((result: any) => {});
    }
  }

  addToCart() {
    let specificationPriceTotal = 0;
    let specificationPrice = 0;
    let specificationList: any[] = [];

    this.current_item.specifications.forEach(
      (specification_group: any, specification_group_index: number) => {
        if (
          !specification_group.formule_id ||
          specification_group.formule_id == this.current_formule.formule_id
        ) {
          let specificationItemCartList: any[] = [];
          specification_group.list.forEach(
            (specification: any, specification_index: number) => {
              if (
                specification.is_default_selected &&
                !specification.is_close &&
                !specification_group.is_close
              ) {
                specificationPrice = specificationPrice + specification.price;
                specificationPriceTotal =
                  specificationPriceTotal + specification.price;
                specificationItemCartList.push(specification);
              }
            }
          );

          if (specificationItemCartList.length > 0) {
            let specificationsItem_json = {
              list: specificationItemCartList,
              unique_id: specification_group.unique_id,
              name: specification_group.name,
              price: specificationPrice,
              is_required: specification_group.is_required,
              min_selection: specification_group.min_selection,
              max_selection: specification_group.max_selection,
              type: specification_group.type,
              formule_id: specification_group.formule_id,
            };
            specificationList.push(specificationsItem_json);
          }
          specificationPrice = 0;
        }
      }
    );

    this.cartProductItems.item_id = this.current_item._id;
    this.cartProductItems.unique_id = this.current_item.unique_id;
    this.cartProductItems.item_name = this.current_item.name;
    this.cartProductItems.quantity = this.qty;
    this.cartProductItems.image_url = this.current_item.image_url;
    this.cartProductItems.details = this.current_item.details;
    this.cartProductItems.specifications = specificationList;
    this.cartProductItems.item_price = this.current_item.price;
    this.cartProductItems.offer_price = this.current_item.offer_price
      ? this.current_item.offer_price
      : 0;
    this.cartProductItems.is_buy_one_get_one = this.current_item
      .is_buy_one_get_one
      ? this.current_item.is_buy_one_get_one
      : false;
    this.cartProductItems.total_specification_price = specificationPriceTotal;
    this.cartProductItems.total_item_and_specification_price = this.total;
    this.cartProductItems.note_for_item = JSON.parse(
      JSON.stringify(this.note_for_item)
    );
    if (this.current_formule.formule_id) {
      this.cartProductItems.formule_list = [this.current_formule];
      this.cartProductItems.item_price = Number(this.current_formule.price);
      this.cartProductItems.item_name = this.current_formule.name;
    }

    this.note_for_item = '';

    this.helper.common_data._user_cart.cart_main_item.push(
      this.current_main_item
    );
    this.helper.common_data._user_cart.cart_data.total_cart_amount =
      this.helper.common_data._user_cart.cart_data.total_cart_amount +
      this.total -
      (this.current_item.offer_price ? this.current_item.offer_price : 0);

    if (this.isProductExistInLocalCart(this.cartProductItems)) {
    } else {
      let cartProductItemsList = [];
      cartProductItemsList.push(this.cartProductItems);
      this.cartProducts.items = cartProductItemsList;
      this.cartProducts.product_id = this.current_item.product_id;
      this.cartProducts.product_name = this.product_name;
      this.cartProducts.unique_id = this.product_unique_id;
      this.cartProducts.total_item_price = this.total;
      // this.helper.user_cart.cart_data.is_get_card = true;

      this.helper.common_data._user_cart.cart_data.cart.push(this.cartProducts);
      this.helper.common_data._user_cart.cart_data.selectedStoreId =
        this.helper.website_data.detail.store_id;
      this.helper.common_data._user_cart.cart_data.max_item_quantity_add_by_user =
        this.current_store.max_item_quantity_add_by_user;
      if (
        this.helper.common_data._user_cart.cart_data.pickup_addresses.length ==
        0
      ) {
        this.helper.common_data.cart_data.pickup_address.location =
          this.current_store.location;
        this.helper.common_data.cart_data.pickup_address.address =
          this.current_store.address;
        this.helper.common_data.cart_data.pickup_address.user_type =
          this.current_store.user_type;
        this.helper.common_data.cart_data.pickup_address.user_details = {
          name: this.current_store.name,
          country_phone_code: this.current_store.country_phone_code,
          phone: this.current_store.phone,
          email: this.current_store.email,
        };
        this.helper.common_data._user_cart.cart_data.pickup_addresses.push(
          this.helper.common_data.cart_data.pickup_address
        );
      }
      if (
        this.helper.common_data._user_cart.cart_data.destination_addresses
          .length == 0
      ) {
        this.helper.common_data._user_cart.cart_data.destination_addresses.push(
          this.helper.common_data.cart_data.destination_address
        );
      }
      this.helper.common_data._user_cart.cart_data.city_id =
        this.helper.common_data.cart_data.city_id;
      this.helper.common_data._user_cart.cart_data.total_item++;

      this.addItemInServerCart(
        this.helper.common_data._user_cart.cart_data.cart
      );
    }
  }

  isProductExistInLocalCart(cartProductItems: any) {
    let bool = false;
    this.helper.common_data._user_cart.cart_data.cart.forEach(
      (cart_item: any) => {
        if (
          cart_item.product_id == this.current_item.product_id &&
          bool == false
        ) {
          cart_item.items.push(cartProductItems);
          cart_item.total_item_price = cart_item.total_item_price + this.total;
          this.helper.common_data._user_cart.cart_data.total_item++;
          bool = true;
          this.addItemInServerCart(
            this.helper.common_data._user_cart.cart_data.cart
          );
        }
      }
    );
    return bool;
  }

  addItemInServerCart(cartData: any) {
    this.cartProductItems = {
      item_id: '',
      unique_id: 0,
      item_name: '',
      quantity: 0,
      image_url: [],
      formule_list: [],
      details: '',
      specifications: [],
      item_price: 0,
      is_buy_one_get_one: false,
      offer_price: 0,
      total_specification_price: 0,
      total_item_and_specification_price: 0,
      note_for_item: '',
    };
    this.cartProducts = {
      items: [],
      product_id: '',
      product_name: '',
      unique_id: 0,
      total_item_price: 0,
    };
    this.cart_service.add_to_cart();
    this.modalRef.close();
    this.offer_service.check_cart_offer(
      this.current_store,
      this.delivery_currency
    );
    if (
      this.helper.common_data._user_cart.cart_data.total_item == 1 &&
      screen.width > 768
    ) {
      this.is_show_cart = true;
    }
  }

  remove_from_cart(product_index: number, item_index: number) {
    if (
      this.helper.common_data._user_cart.cart_data.is_catering ==
      this.helper.common_data.cart_data.is_catering
    ) {
      this.cart_service.remove_from_cart(
        product_index,
        item_index,
        null,
        null,
        null
      );
      this.offer_service.check_cart_offer(
        this.current_store,
        this.delivery_currency
      );
    }
  }

  cart_decrease_qty(product_index: number, item_index: number) {
    if (
      this.helper.common_data._user_cart.cart_data.is_catering ==
      this.helper.common_data.cart_data.is_catering
    ) {
      this.cart_service.decrease_qty(
        product_index,
        item_index,
        null,
        null,
        null
      );
      this.offer_service.check_cart_offer(
        this.current_store,
        this.delivery_currency
      );
    }
  }

  cart_increase_qty(product_index: number, item_index: number) {
    if (
      this.helper.common_data._user_cart.cart_data.is_catering ==
      this.helper.common_data.cart_data.is_catering
    ) {
      this.cart_service.increase_qty(product_index, item_index, null, null);
      this.offer_service.check_cart_offer(
        this.current_store,
        this.delivery_currency
      );
    }
  }

  incerase_qty() {
    this.qty++;
    this.calculateTotalAmount();
  }

  decrease_qty() {
    if (this.qty > 1) {
      this.qty--;
      this.calculateTotalAmount();
    }
  }

  open_edit_item_modal(
    template: TemplateRef<any>,
    item: any,
    selected_product_index: number,
    selected_item_index: number
  ) {
    this.current_formule = { formule_id: '' };
    if (item.formule_list && item.formule_list.length > 0) {
      this.current_formule = JSON.parse(JSON.stringify(item.formule_list[0]));
    }
    this.edit_cart_item = true;
    this.selected_item_index = selected_item_index;
    this.selected_product_index = selected_product_index;
    let item_index =
      this.helper.common_data._user_cart.cart_main_item.findIndex(
        (x: any) => x._id == item.item_id
      );
    let current_specification =
      this.helper.common_data._user_cart.cart_main_item[item_index]
        .specifications;
    // current_specification = current_specification.filter((x:any)=>{
    //   if(!x.formule_id || x.formule_id==this.current_formule.formule_id){
    //     return x;
    //   }
    // })
    let order_specification = item.specifications;
    let new_specification: any[] = [];

    current_specification.forEach((x: any) => {
      var index = order_specification.findIndex(
        (order_sp: any) => order_sp.unique_id == x.unique_id
      );
      if (x.formule_id) {
        index = order_specification.findIndex(
          (order_sp: any) =>
            order_sp.unique_id == x.unique_id &&
            order_sp.formule_id == x.formule_id
        );
      }

      if (index == -1) {
        x.list.forEach((y: any) => {
          y.price = Number(y.price);
        });
        new_specification.push(x);
      } else {
        var new_specification_list: any[] = [];
        x.list.forEach((y: any) => {
          y.price = Number(y.price);
          var list_index = order_specification[index].list.findIndex(
            (order_sp_list: any) => order_sp_list.unique_id == y.unique_id
          );
          if (list_index == -1) {
            y.is_default_selected = false;
            new_specification_list.push(y);
          } else {
            order_specification[index].list[list_index].price = y.price;
            new_specification_list.push(
              order_specification[index].list[list_index]
            );
          }
        });
        let json = {
          list: new_specification_list,
          unique_id: x.unique_id,
          name: x.name,
          formule_id: x.formule_id,
          is_required: x.is_required,
          min_selection: x.min_selection,
          max_selection: x.max_selection,
          price: Number(x.price),
          type: x.type,
        };
        new_specification.push(json);
      }
    });
    this.qty = item.quantity;
    this.required_count = 0;
    this.current_item = JSON.parse(JSON.stringify(item));
    this.current_item.price =
      this.helper.common_data._user_cart.cart_main_item[item_index].price;
    this.current_item.name =
      this.helper.common_data._user_cart.cart_main_item[item_index].name;
    // new_specification.sort(this.sortFormule);
    this.current_item.specifications = new_specification;
    this.current_item.formule_list =
      this.helper.common_data._user_cart.cart_main_item[
        item_index
      ].formule_list;
    this.calculate_is_required();
    this.edit_item_calculateTotalAmount();
    this.note_for_item = item.note_for_item;
    if (
      this.offer_service.check_buy_one_get_one(this.current_item) &&
      this.current_item.is_buy_one_get_one
    ) {
      this.current_item.is_buy_one_get_one = true;
    } else {
      this.current_item.is_buy_one_get_one = false;
    }
    this.openModal1(template);
  }

  edit_item_calculateTotalAmount() {
    this.total = this.current_item.price;
    if (this.current_formule.formule_id && this.current_formule.price) {
      this.total = Number(this.current_formule.price);
    }
    this.required_temp_count = 0;
    this.current_item.specifications.forEach(
      (specification_group: any, specification_group_index: number) => {
        if (
          !specification_group.formule_id ||
          specification_group.formule_id == this.current_formule.formule_id
        ) {
          let isAllowed = false;

          if (
            specification_group.min_selection == undefined ||
            specification_group.max_selection == undefined
          ) {
            if (specification_group.type == 1) {
              specification_group.min_selection = 1;
              specification_group.max_selection = 1;
              // specification_group.is_required = true;
            } else {
              specification_group.max_selection =
                specification_group.list.length;
              if (specification_group.is_required) {
                specification_group.min_selection = 1;
              } else {
                specification_group.min_selection = 0;
              }
            }
          }
          if (specification_group.max_selection == 0) {
            specification_group.max_selection = 1;
          }

          var default_selected_count = 0;
          specification_group.selected_specification = 0;
          specification_group.list.forEach(
            (specification: any, specification_index: number) => {
              specification.price = Number(specification.price);
              if (
                specification.is_default_selected &&
                !specification.is_close &&
                !specification_group.is_close
              ) {
                this.total = this.total + specification.price;
                default_selected_count++;
                specification_group.selected_specification++;
              }
              specification_group.default_selected_count =
                default_selected_count;
            }
          );

          if (
            specification_group.type == 1 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= 1) {
              this.required_temp_count++;
            }
          } else if (
            specification_group.type == 2 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= specification_group.min_selection) {
              this.required_temp_count++;
            }
          }
        }
      }
    );

    this.total = this.total * this.qty;

    this.offer_service.check_item_offer(this.current_item, this.total);
  }

  updateCart() {
    if (
      this.helper.common_data._user_cart.cart_data.is_catering ==
      this.helper.common_data.cart_data.is_catering
    ) {
      let specificationPriceTotal = 0;
      let specificationPrice = 0;
      let specificationList: any[] = [];
      this.current_item.specifications.forEach(
        (specification_group: any, specification_group_index: number) => {
          if (
            !specification_group.formule_id ||
            specification_group.formule_id == this.current_formule.formule_id
          ) {
            let specificationItemCartList: any[] = [];
            specification_group.list.forEach(
              (specification: any, specification_index: number) => {
                if (
                  specification.is_default_selected &&
                  !specification.is_close &&
                  !specification_group.is_close
                ) {
                  specificationPrice = specificationPrice + specification.price;
                  specificationPriceTotal =
                    specificationPriceTotal + specification.price;
                  specificationItemCartList.push(specification);
                }
              }
            );

            if (specificationItemCartList.length > 0) {
              let specificationsItem_json = {
                list: specificationItemCartList,
                unique_id: specification_group.unique_id,
                name: specification_group.name,
                price: specificationPrice,
                type: specification_group.type,
                formule_id: specification_group.formule_id,
                is_required: specification_group.is_required,
                min_selection: specification_group.min_selection,
                max_selection: specification_group.max_selection,
              };
              specificationList.push(specificationsItem_json);
            }
            specificationPrice = 0;
          }
        }
      );

      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].item_price = this.current_item.price;
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].offer_price =
        this.current_item.offer_price;
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].is_buy_one_get_one =
        this.current_item.is_buy_one_get_one;
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].total_specification_price =
        specificationPriceTotal;
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].total_item_and_specification_price =
        this.total;
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].note_for_item = this.note_for_item;
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].specifications = specificationList;
      if (this.current_formule.formule_id) {
        this.helper.common_data._user_cart.cart_data.cart[
          this.selected_product_index
        ].items[this.selected_item_index].formule_list = [this.current_formule];
        this.helper.common_data._user_cart.cart_data.cart[
          this.selected_product_index
        ].items[this.selected_item_index].item_price = Number(
          this.current_formule.price
        );
        this.helper.common_data._user_cart.cart_data.cart[
          this.selected_product_index
        ].items[this.selected_item_index].item_name = this.current_formule.name;
      } else {
        this.helper.common_data._user_cart.cart_data.cart[
          this.selected_product_index
        ].items[this.selected_item_index].formule_list = [];
      }
      var total_item_price = 0;
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items.forEach((item: any) => {
        total_item_price =
          total_item_price + item.total_item_and_specification_price;
      });
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].total_item_price = total_item_price;
      this.cart_service.calculateTotalAmount(null, null, null);
      this.modalRef.close();
      this.offer_service.check_cart_offer(
        this.current_store,
        this.delivery_currency
      );
    }
  }

  async get_order_history(template: TemplateRef<any>) {
    let json = {
      store_id: this.helper.website_data.detail.store_id,
      user_id: this.user_id,
      server_token: this.server_token,
    };

    var res_data = await this.helper.http_post_method_requester(
      this.helper.POST_METHOD.ORDER_HISTORY,
      json,
      true,
      true,
      true,
      true
    );

    if (res_data.success) {
      this.order_history = res_data.order_list;
      this.order_history.sort(this.sortOrder);

      this.order_history.forEach((order) => {
        order.is_full_order_available = true;
        order.cart_detail.order_details.forEach((product: any) => {
          var product_index = this.filtered_product_list.findIndex(
            (x: any) => x._id._id == product.product_id
          );
          product.items.forEach((item: any) => {
            if (product_index !== -1) {
              var item_index = this.filtered_product_list[
                product_index
              ].items.findIndex((x: any) => x._id == item.item_id);
              if (item_index !== -1) {
                item.is_available = true;

                item.specifications.forEach((specification: any) => {
                  var specification_index = this.filtered_product_list[
                    product_index
                  ].items[item_index].specifications.findIndex(
                    (x: any) => x.unique_id == specification.unique_id
                  );

                  specification.list.forEach((sp: any) => {
                    if (specification_index !== -1) {
                      var sp_index = this.filtered_product_list[
                        product_index
                      ].items[item_index].specifications[
                        specification_index
                      ].list.findIndex((x: any) => x._id == sp._id);
                      if (sp_index !== -1) {
                        sp.is_available = true;
                      } else {
                        sp.is_available = false;
                        order.is_full_order_available = false;
                      }
                    } else {
                      sp.is_available = false;
                      order.is_full_order_available = false;
                    }
                  });
                });
              } else {
                item.is_available = false;
                order.is_full_order_available = false;
              }
            } else {
              item.is_available = false;
              order.is_full_order_available = false;
            }
          });
        });
      });
    } else {
      this.order_history = [];
    }
    this.modalRef = this.modalService.open(template, {
      centered: true,
      size: 'lg',
      modalDialogClass: 're-order-popup popup-mid',
    });
  }

  async reorder(order_detail: any) {
    this.helper.common_data._user_cart.cart_data.total_cart_amount = 0;
    this.helper.common_data._user_cart.cart_data.cart = [];
    this.helper.common_data._user_cart.cart_data.total_item = 0;

    order_detail.order_details.forEach((product: any) => {
      var items: any[] = [];
      product.items.forEach((item: any) => {
        if (item.is_available) {
          items.push(item);
          this.helper.common_data._user_cart.cart_data.total_cart_amount =
            this.helper.common_data._user_cart.cart_data.total_cart_amount +
            item.total_item_and_specification_price -
            (item.offer_price ? item.offer_price : 0);
          this.helper.common_data._user_cart.cart_data.total_item++;
        }
      });
      product.items = items;
      this.helper.common_data._user_cart.cart_data.cart.push(product);
    });
    this.helper.common_data._user_cart.cart_data.selectedStoreId =
      this.helper.website_data.detail.store_id;
    this.helper.common_data._user_cart.cart_data.max_item_quantity_add_by_user =
      this.current_store.max_item_quantity_add_by_user;
    this.helper.common_data._user_cart.cart_data.pickup_addresses =
      order_detail.pickup_addresses;
    this.helper.common_data._user_cart.cart_data.destination_addresses =
      order_detail.destination_addresses;
    this.helper.common_data._user_cart.cart_data.city_id = order_detail.city_id;

    this.addItemInServerCart(this.helper.common_data._user_cart.cart_data.cart);
  }

  sortOrder(a: any, b: any) {
    if (a.unique_id < b.unique_id) return 1;
    if (a.unique_id > b.unique_id) return -1;
    return 0;
  }

  //////////////

  select_delivery_option(ipickup_delivery: boolean, is_catering: boolean) {
    this.helper.common_data.cart_data.ipickup_delivery = ipickup_delivery;

    let catering = this.helper.common_data.cart_data.is_catering;
    this.helper.common_data.cart_data.is_catering = is_catering;
    if (this.helper.common_data._user_cart.cart_data.cart.length === 0) {
      this.helper.common_data._user_cart.cart_data.is_catering = is_catering;
    }

    if (catering !== is_catering) {
      this.get_product_item_list({});
    }

    if (!ipickup_delivery) {
      setTimeout(() => {
        this.delivery_setting_service.load_autocomplete(
          this.current_store,
          true
        );
      }, 100);
    }
    this.delivery_setting_service.check_and_set_schedule(this.current_store);
    if (this.helper.common_data.cart_data.is_schedule_order) {
      this.delivery_setting_service.check_open(
        this.current_store,
        this.helper.common_data.cart_data.schedule_date,
        false,
        true
      );
    } else {
      let date: any = this.helper.common_data.cart_data.server_date;
      date = new Date(date).toLocaleString('en-US', {
        timeZone: this.helper.common_data.cart_data.timezone,
      });
      date = new Date(date);
      this.delivery_setting_service.check_open(
        this.current_store,
        date,
        true,
        true
      );
    }
    // this.delivery_setting_service.check_open(this.current_store, this.helper.common_data.cart_data.schedule_date, false, true)
    // this.delivery_setting_service.check_and_set_schedule(this.current_store);
    this.delivery_setting_service.calculate_delivery_time(
      this.current_store.preparation_time_for_pickup,
      this.current_store.preparation_time_for_delivery,
      this.current_store.preparation_time_for_catering
    );
  }

  show_header_popup() {
    if (this.helper.common_data.cart_data.is_order_pay) {
      this.helper.router.navigate(['home/table']);
    } else {
      setTimeout(() => {
        this.is_show_header_error = false;
        this.is_show_header_popup = !this.is_show_header_popup;
        if (!this.helper.common_data.cart_data.ipickup_delivery) {
          setTimeout(() => {
            this.delivery_setting_service.load_autocomplete(
              this.current_store,
              true
            );
          }, 100);
        }
        document.getElementsByTagName('body')[0].classList.add('modal-open');
      });
    }
  }

  close_header_popup() {
    this.is_show_header_popup = false;
    document.getElementsByTagName('body')[0].classList.remove('modal-open');
    if (this.delivery_setting_service.is_show_address) {
      this.delivery_setting_service.set_show_address();
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: any) {
    setTimeout(() => {
      var elem: any = document.getElementById('shop-header-main');
      var height = elem.offsetHeight;

      var elem1: any = document.getElementById('shop-page-header');
      var elem2: any = document.getElementById('estimated-delivery');
      if (window.scrollY > elem1.offsetHeight + elem2.offsetHeight) {
        if (window.innerWidth > 769 || !this.is_show_cart) {
          this.is_header_sticky = true;
        }
        var elem3: any = document.getElementById('menu-content');
        height = elem3.offsetHeight;
        this.margin_top = height;
      } else {
        this.is_header_sticky = false;
        height = height - window.scrollY;
        this.margin_top = 0;
      }
      this.header_height = height;
    }, 100);
  }

  go_to_checkout() {
    var total_cart_amount =
      this.helper.common_data._user_cart.cart_data.total_cart_amount;
    // if(this.offer_service.cart_offer_message){
    //   total_cart_amount = total_cart_amount - this.offer_service.cart_offer_value;
    // }
    if (
      total_cart_amount >=
      this.helper.common_data._user_cart.cart_data.min_order_price
    ) {
      if (
        ((this.current_store.is_pickup_possible &&
          this.helper.common_data.cart_data.ipickup_delivery) ||
          (this.current_store.is_delivery_possible &&
            !this.helper.common_data.cart_data.ipickup_delivery)) &&
        (!this.delivery_setting_service.schedule_time_error ||
          !this.delivery_setting_service.is_schedule_order) &&
        ((this.helper.common_data.cart_data.destination_address.location &&
          this.helper.common_data.cart_data.destination_address.location[0] &&
          this.helper.common_data.cart_data.destination_address.location[1]) ||
          this.helper.common_data.cart_data.ipickup_delivery)
      ) {
        this.is_show_header_error = false;
        document.getElementsByTagName('body')[0].classList.remove('modal-open');
        if (this.helper.common_data.cart_data.is_order_pay) {
          this.helper.router.navigate([
            '/checkout/table/' + this.helper.common_data.cart_data.table_no,
          ]);
        } else {
          this.helper.router.navigate(['/checkout']);
        }
        this.helper.common_data.observable.next({
          current_store: this.current_store,
        });
      } else {
        window.scroll(0, 0);
        this.is_show_header_error = true;
        this.show_header_popup();
      }
    }
  }

  show_mobile_cart() {
    // if(this.helper.common_data._user_cart.cart_data.cart.length>0){
    this.is_header_sticky = false;
    this.is_show_cart = true;
    document.getElementsByTagName('body')[0].classList.add('modal-open');
    // }
  }

  close_cart() {
    this.is_show_cart = false;
    document.getElementsByTagName('body')[0].classList.remove('modal-open');
  }

  check_button(item_id: string) {
    var div: any = document.getElementById(item_id);
    if (div) {
      if (div.scrollHeight > 75) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get_style() {
    let style = {
      background:
        this.current_store.order_background_type == 1
          ? 'url(' +
            this.helper.image_base_url +
            this.current_store.order_background_image +
            ')'
          : this.current_store.order_background_color,
      'margin-top': this.margin_top + 'px',
    };
    return style;
  }

  clear_cart() {
    this.cart_service.clear_cart(null);
    this.modalRef.close();
  }
}
